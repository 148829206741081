import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_TOKEN, SIGNIN,
} from '../constants/Auth';
import {
	authenticated,
	showAuthMessage, signOut,
} from "../actions/Auth";
import JwtAuthService from "../../services/JwtAuthService";

export function* signUpWithFBEmail() {
	yield takeEvery(SIGNIN, function* ({payload}) {
		try {
			const data = yield call(JwtAuthService.login, payload);
			if (data.message) {
				yield put(showAuthMessage(data.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, "Bearer " + data.accessToken);
				yield put(authenticated(data.accessToken));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export default function* rootSaga() {
  yield all([
		fork(signOut),
		fork(signUpWithFBEmail),
  ]);
}
