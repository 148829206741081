import ruRU from "antd/es/locale/ru_RU";
import uzMsg from "../locales/uz_UZ.json";

const UzLang = {
    antd: ruRU,
    locale: 'uz-UZ',
    messages: {
        ...uzMsg
    },
};
export default UzLang;

