import {
  DashboardOutlined,
  DollarOutlined,
  AppstoreFilled, CopyOutlined, UserOutlined, ShopOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}`,
  title: 'sidenav.dashboard',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'dashboards-default',
      path: `${APP_PREFIX_PATH}/dash`,
      title: 'sidenav.dashboard.default',
      icon: DashboardOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'products',
      path: `${APP_PREFIX_PATH}/products`,
      title: 'sidenav.apps.products',
      icon: AppstoreFilled,
      breadcrumb: false,
      submenu:[
        {
          key: 'categories-list',
          path: `${APP_PREFIX_PATH}/products/categories`,
          title: 'sidenav.apps.productCategories',
          breadcrumb: true,
          submenu: [

          ]
        },
        {
          key: 'products-list',
          path: `${APP_PREFIX_PATH}/products/list`,
          title: 'sidenav.apps.products',
          breadcrumb: true,
          submenu: [

          ]
        },
        {
          key: 'technical-maps',
          path: `${APP_PREFIX_PATH}/products/technical-maps`,
          title: 'sidenav.apps.products.technicalMaps',
          breadcrumb: true,
          submenu: []
        }
      ]
    },
    {
      key: 'documents',
      path: `${APP_PREFIX_PATH}/documents`,
      title: 'sidenav.apps.documents',
      icon: CopyOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'documents-purchases-list',
          path: `${APP_PREFIX_PATH}/documents/purchases`,
          title: 'sidenav.apps.documents.purchases',
          breadcrumb: true,
          submenu: [

          ]
        }
      ]
    },
    {
      key: 'users',
      path: `${APP_PREFIX_PATH}/users`,
      title: 'sidenav.apps.users',
      icon: UserOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'warehouse',
      path: `${APP_PREFIX_PATH}/warehouse`,
      title: 'sidenav.apps.warehouse',
      icon: ShopOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'reports',
      path: `${APP_PREFIX_PATH}/reports`,
      title: 'sidenav.apps.reports',
      icon: DollarOutlined,
      breadcrumb: true,
      submenu: []
    }
  ]
}]

const navigationConfig = [
  ...dashBoardNavTree,
]

export default navigationConfig;
